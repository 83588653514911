
export const COLORS = {
    primaryColor: {
      light: '#E7E7E7', // white
      dark: '#2E2E2E', // near-black
    },
    primaryColorDarkerShadow: {
      light: '#cecece', // white
      dark: '#252525', // navy navy blue
    },
    primaryColorLighterShadow: {
      light: '#FFFFFF', // Pinkish-red
      dark: '#353434', // Yellow
    },
    primaryTextColor: {
      light: '#000000', // Purplish-blue
      dark: '#ffffff', // Cyan
    },
    // Grays, scaling from least-noticeable to most-noticeable
    offsetTextColor: {
      light: '#373737',
      dark: '#FFFFFF',
    },
  };

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';